<!-- start page title -->
<app-pagetitle title="Datatables" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Advanced Data Table</h4>
                <p class="card-sub-title">
                    Example of datatable.
                </p>
                <div class="row mb-md-2">
                    <div class="col-sm-12 col-md-6">
                        <div class="dataTables_length"
                            id="tickets-table_length"><label
                                class="d-inline-flex align-items-center">Show
                                <select name="tickets-table_length"
                                    aria-controls="tickets-table"
                                    name="pageSize"
                                    [(ngModel)]="service.pageSize"
                                    class="form-control form-control-sm mx-2">
                                    <option [ngValue]="10">10</option>
                                    <option [ngValue]="25">25</option>
                                    <option [ngValue]="50">50</option>
                                    <option [ngValue]="100">100</option>
                                </select> entries</label></div>
                    </div>
                    <!-- Search -->
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_filter" class="dataTables_filter
                            text-md-end"><label
                                class="d-inline-flex align-items-center">Search:
                                <input type="text" name="searchTerm"
                                    class="form-control form-control-sm ms-2"
                                    aria-controls="tickets-table"
                                    [(ngModel)]="service.searchTerm"></label></div>
                    </div>
                    <!-- End search -->
                </div>
                <!-- Table -->
                <div class="table-responsive">
                    <table id="basic-datatable"
                        class="table table-bordered dt-responsive nowrap
                        datatables no-footer dtr-inline">
                        <thead>
                            <tr>
                                <th sortable="id" (sort)="onSort($event)">id</th>
                                <th sortable="name" (sort)="onSort($event)">Name</th>
                                <th sortable="position" (sort)="onSort($event)">Position</th>
                                <th sortable="office" (sort)="onSort($event)">Office</th>
                                <th sortable="age" (sort)="onSort($event)">Age</th>
                                <th sortable="date" (sort)="onSort($event)">Start
                                    date</th>
                                <th sortable="salary" (sort)="onSort($event)">Salary</th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let table of tables$ | async;let
                            i=index">
                            <tr>
                                <td align="center">
                                    <ngb-highlight [result]="table.id | number"
                                        [term]="service.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.name"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.position"
                                        [term]="service.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.office"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.age | number"
                                        [term]="service.searchTerm">
                                    </ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.date"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                                <td>
                                    <ngb-highlight [result]="table.salary"
                                        [term]="service.searchTerm"></ngb-highlight>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- End table -->
                <div class="row justify-content-md-between align-items-md-center
                    mt-2">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2"
                            id="tickets-table_info" role="status"
                            aria-live="polite">
                            Showing
                            {{service.startIndex}} to
                            {{service.endIndex}} of {{service.totalRecords}}
                            entries
                        </div>
                    </div>
                    <!-- Pagination -->
                    <div class="col-sm-12 col-md-5">
                        <div class="text-md-right float-md-end
                            pagination-rounded">
                            <ngb-pagination [collectionSize]="(total$ | async)!"
                                [(page)]="service.page"
                                [pageSize]="service.pageSize">
                            </ngb-pagination>
                        </div>
                    </div>
                    <!-- End Pagination -->
                </div>
            </div>
        </div>
    </div>
</div>