<!-- start page title -->
<app-pagetitle title="Pricing" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row justify-content-center">
    <div class="col-lg-5">
        <div class="text-center my-3">
            <h4 class="mb-3">Choose your Pricing plan</h4>
            <p class="text-muted mb-4">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium
                doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo veritatis</p>

            <ul class="nav nav-pills pricing-nav-tabs mt-4">
                <li class="nav-item">
                    <a class="nav-link active fw-semibold" routerLink="/pages/pricing">Monthly</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link fw-semibold" routerLink="/pages/pricing">Yearly</a>
                </li>
            </ul>
        </div>
    </div>
</div>
<!-- end row -->

<div class="row g-0">
    <div class="col-xl-3 col-md-6">
        <div class="card plan-box rounded-start rounded-0">
            <div class="card-body p-4">
                <div class="d-flex">
                    <div class="me-3">
                        <i class="mdi mdi-square-edit-outline h1 text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h5 class="mb-1">Starter</h5>
                        <p class="text-muted">Suitable for 1 team member</p>
                    </div>
                </div>
                <div class="py-4 border-bottom">
                    <h3><sup><small>$</small></sup> 19/ <span class="font-size-13 text-muted">Per month</span></h3>
                </div>
                <ul class="list-unstyled plan-features mt-4">
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Live Support</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Unlimited User</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> No Time Tracking</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Setup</li>
                </ul>
                <div class="text-center plan-btn mt-4 mb-2">
                    <a routerLink="pages/pricing" class="btn btn-primary waves-effect waves-light">Sign up Now</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-md-6">
        <div class="card plan-box rounded-0">
            <div class="card-body p-4">
                <div class="d-flex">
                    <div class="me-3">
                        <i class="mdi mdi-medal-outline h1 text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h5 class="mb-1">Professional</h5>
                        <p class="text-muted">Suitable for 3 team member</p>
                    </div>
                </div>
                <div class="py-4 border-bottom">
                    <h3><sup><small>$</small></sup> 29/ <span class="font-size-13 text-muted">Per month</span></h3>
                </div>
                <ul class="list-unstyled plan-features mt-4">
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Live Support</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Unlimited User</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> No Time Tracking</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Setup</li>
                </ul>
                <div class="text-center plan-btn mt-4 mb-2">
                    <a routerLink="pages/pricing" class="btn btn-primary waves-effect waves-light">Sign up Now</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-md-6">
        <div class="card plan-box rounded-0">
            <div class="card-body p-4">
                <div class="d-flex">
                    <div class="me-3">
                        <i class="mdi mdi-layers-triple-outline h1 text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h5 class="mb-1">Enterprise</h5>
                        <p class="text-muted">Suitable for 19 team member</p>
                    </div>
                </div>
                <div class="py-4 border-bottom">
                    <h3><sup><small>$</small></sup> 39/ <span class="font-size-13 text-muted">Per month</span></h3>
                </div>
                <ul class="list-unstyled plan-features mt-4">
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Live Support</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Unlimited User</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> No Time Tracking</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Setup</li>
                </ul>
                <div class="text-center plan-btn mt-4 mb-2">
                    <a routerLink="pages/pricing" class="btn btn-primary waves-effect waves-light">Sign up Now</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xl-3 col-md-6">
        <div class="card plan-box rounded-0 rounded-end">
            <div class="card-body p-4">
                <div class="d-flex">
                    <div class="me-3">
                        <i class="mdi mdi-crown-outline h1 text-primary"></i>
                    </div>
                    <div class="flex-1">
                        <h5 class="mb-1">Unlimited</h5>
                        <p class="text-muted">Suitable for unlimited team member</p>
                    </div>
                </div>
                <div class="py-4 border-bottom">
                    <h3><sup><small>$</small></sup> 49/ <span class="font-size-13 text-muted">Per month</span></h3>
                </div>
                <ul class="list-unstyled plan-features mt-4">
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Live Support</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Unlimited User</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> No Time Tracking</li>
                    <li><i class="mdi mdi-circle-medium text-primary me-2"></i> Free Setup</li>
                </ul>
                <div class="text-center plan-btn mt-4 mb-2">
                    <a routerLink="pages/pricing" class="btn btn-primary waves-effect waves-light">Sign up Now</a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end row -->