<!-- start page title -->
<app-pagetitle title="Chat" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->


<div class="d-lg-flex mb-4">
  <div class="chat-leftsidebar card">
    <div class="p-3 px-4 bg-light">
      <div class="d-flex align-items-start">
        <div class="align-self-center me-3">
          <img src="assets/images/users/avatar-4.jpg" class="avatar-xs rounded-circle" alt="avatar" />
        </div>
        <div class="flex-1">
          <h5 class="font-size-16 mt-0 mb-1">
            <a href="#" class="text-reset">Marcus
              <i class="
                  mdi mdi-circle
                  text-success
                  align-middle
                  font-size-10
                  ms-1
                "></i></a>
          </h5>
          <p class="text-muted mb-0 font-size-12">Available</p>
        </div>

        <div>
          <div class="dropdown chat-noti-dropdown" ngbDropdown>
            <button class="btn dropdown-toggle py-0" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" ngbDropdownToggle>
              <i class="mdi mdi-dots-horizontal"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript:void(0);">Profile</a>
              <a class="dropdown-item" href="javascript:void(0);">Edit</a>
              <a class="dropdown-item" href="javascript:void(0);">Add Contact</a>
              <a class="dropdown-item" href="javascript:void(0);">Settings</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3">
      <div class="search-box chat-search-box">
        <div class="position-relative">
          <input type="text" class="form-control border-light rounded" placeholder="Search..." />
          <i class="mdi mdi-magnify search-icon"></i>
        </div>
      </div>
    </div>

    <div>
      <div class="border-top">
        <div class="p-4" data-simplebar style="max-height: 470px">
          <div>
            <h5 class="font-size-13 text-uppercase mb-3">
              <i class="mdi mdi-account-outline me-1"></i> Contacts
            </h5>
            <ngx-simplebar style="position: relative; height: 410px" #scrollEle>
              <ul class="list-unstyled chat-list">
                <li class="" *ngFor="let data of chatData">
                  <a href="javascript: void(0);" (click)="chatUsername(data.name, data.image)">
                    <div class="d-flex align-items-start">
                      <div class="user-img online align-self-center me-3" *ngIf="data.image">
                        <img src="{{ data.image }}" class="rounded-circle avatar-xs" alt="avatar" />
                        <span class="user-status"></span>
                      </div>

                      <div class="avatar-xs align-self-center me-3" *ngIf="!data.image">
                        <span class="
                            avatar-title
                            rounded-circle
                            bg-primary
                          ">
                          {{ data.name.charAt(0) }}
                        </span>
                      </div>
                      <div class="flex-1 overflow-hidden">
                        <h5 class="text-truncate font-size-14 mb-1">
                          {{ data.name }}
                        </h5>
                        <p class="text-truncate font-size-13 mb-0">
                          {{ data.message }}
                        </p>
                      </div>
                      <div class="font-size-11">{{ data.time }}</div>
                    </div>
                  </a>
                </li>
              </ul>
            </ngx-simplebar>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end chat-leftsidebar -->

  <div class="w-100 user-chat mt-4 mt-sm-0 ms-lg-1">
    <div class="card">
      <div class="p-3 px-lg-4 border-bottom">
        <div class="row">
          <div class="col-md-4 col-6">
            <div class="d-flex align-items-center">
              <div class="d-block d-lg-none me-2 ms-0">
                <a href="javascript: void(0);" class="user-chat-remove text-muted font-size-16 p-2"><i
                    class="ri-arrow-left-s-line"></i></a>
              </div>
              <div class="me-3 ms-0">
                <img *ngIf="profile" src="{{profile}}" class="rounded-circle avatar-xs" alt="avatar" />
                <div class="avatar-xs align-self-center" *ngIf="!profile">
                  <span class="
                      avatar-title
                      rounded-circle
                      bg-primary
                    ">
                    {{ username.charAt(0) }}
                  </span>
                </div>
              </div>
              <div class="flex-1 overflow-hidden">
                <h5 class="font-size-16 mb-0 text-truncate">
                  <a href="#" class="text-reset user-profile-show">{{username}}</a>
                  <i class="
                      mdi mdi-circle
                      text-success
                      align-middle
                      font-size-10
                      ms-1
                    "></i>
                </h5>
              </div>
            </div>
          </div>
          <div class="col-md-8 col-6">
            <ul class="list-inline user-chat-nav text-end mb-0">
              <li class="list-inline-item">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-magnify"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end dropdown-menu-md p-0" ngbDropdownMenu>
                    <form class="p-2">
                      <div>
                        <input type="text" class="form-control border-light rounded" placeholder="Search..." />
                      </div>
                    </form>
                  </div>
                </div>
              </li>

              <li class="list-inline-item">
                <div class="dropdown" ngbDropdown>
                  <button class="btn nav-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                    <i class="mdi mdi-dots-horizontal"></i>
                  </button>
                  <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a class="dropdown-item" href="javascript:void(0);">Profile</a>
                    <a class="dropdown-item" href="javascript:void(0);">Archive</a>
                    <a class="dropdown-item" href="javascript:void(0);">Muted</a>
                    <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="py-lg-3 px-lg-2">
        <div class="chat-conversation p-3">
          <ngx-simplebar style="height: 455px;">
            <ul class="list-unstyled mb-0">
              <li class="chat-day-title">
                <div class="title">Today</div>
              </li>
              <li *ngFor="let data of chatMessagesData" [ngClass]="{ 'right': data.align === 'right' }">
                <div class="conversation-list d-flex" [ngClass]="{ 'order-3': data.align === 'right' }">
                  <div class="chat-user-img online align-self-start" [ngClass]="{ 'order-3': data.align === 'right' }">
                    <img *ngIf="data.profile" :src="{{data.profile}}" class="rounded-circle avatar-xs" alt="avatar" />
                    <div *ngIf="!data.profile" class="avatar-xs align-self-center"><span
                        class="avatar-title rounded-circle bg-primary"> {{data.name.charAt(0)}} </span></div>
                  </div>
                  <div class="ctext-wrap">
                    <div class="ctext-wrap-content">
                      <p class="mb-0">
                        {{ data.message }}
                        <span class="d-inline-block font-size-12 text-muted ms-3">{{ data.time }}</span>
                      </p>
                    </div>
                    <div class="dropdown align-self-start" ngbDropdown>
                      <a class="dropdown-toggle" href="javascript:void(0);" role="button" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false" ngbDropdownToggle>
                        <i class="mdi mdi-dots-vertical"></i>
                      </a>
                      <div class="dropdown-menu" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript:void(0);">Copy</a>
                        <a class="dropdown-item" href="javascript:void(0);">Save</a>
                        <a class="dropdown-item" href="javascript:void(0);">Forward</a>
                        <a class="dropdown-item" href="javascript:void(0);">Delete</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </ngx-simplebar>
        </div>
      </div>

      <form (ngSubmit)="messageSave()" [formGroup]="formData" class="row">
        <div class="p-3 chat-input-section">
          <div class="row">
            <div class="col">
              <div class="position-relative">
                <input formControlName="message" [ngClass]="{'is-invalid': chatSubmit && form.message.errors}"
                  type="text" class="form-control chat-input rounded" placeholder="Enter Message..." />
                <div *ngIf="chatSubmit && form.message.errors" class="invalid-feedback">
                  <span *ngIf="form.message.errors.required">This value is required.</span>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <button type="submit" class="btn btn-primary chat-send w-md waves-effect waves-light">
                <span class="d-none d-sm-inline-block me-2">Send</span>
                <i class="mdi mdi-send float-end"></i>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>