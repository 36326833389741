<!-- start page title -->
<app-pagetitle title="Inbox" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <!-- Left sidebar -->
        <div class="email-leftbar card">
            <button type="button" class="btn btn-danger btn-block" data-toggle="modal" data-target="#composemodal"
                (click)="open(content)">
                Compose
            </button>
            <div class="mail-list mt-4">
                <a href="javascript:void(0);" class="active"><i class="bx bxs-inbox font-size-16 align-middle me-2"></i>
                    Inbox <span class="ms-1 float-end">(18)</span></a>
                <a href="javascript:void(0);"><i class="bx bx-star font-size-16 align-middle me-2"></i>Starred</a>
                <a href="javascript:void(0);"><i
                        class="bx bxs-bookmark font-size-16 align-middle me-2"></i>Important</a>
                <a href="javascript:void(0);"><i class="bx bx-file font-size-16 align-middle me-2"></i>Draft</a>
                <a href="javascript:void(0);"><i class="bx bx-mail-send font-size-16 align-middle me-2"></i>Sent
                    Mail</a>
                <a href="javascript:void(0);"><i class="bx bx-trash font-size-16 align-middle me-2"></i>Trash</a>
            </div>

            <h6 class="mt-4">Labels</h6>
            <div class="mail-list mt-1">
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-info me-2"></span>Theme
                    Support</a>
                <a href="javascript:void(0);"><span
                        class="mdi mdi-circle-outline text-warning me-2"></span>Freelance</a>
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-primary me-2"></span>Social</a>
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-danger me-2"></span>Friends</a>
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-success me-2"></span>Family</a>
            </div>

            <ng-template #content let-modal>
                <div class="modal-header">
                    <h5 class="modal-title" id="composemodalTitle">New Message</h5>
                    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="To">
                        </div>
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Subject">
                        </div>
                        <div class="mb-3">
                            <ckeditor [editor]="Editor" data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
                    <button type="button" class="btn btn-primary">Send <i
                            class="fab fa-telegram-plane ms-1"></i></button>
                </div>
            </ng-template>

        </div>
        <!-- End Left sidebar -->

        <!-- Right Sidebar -->
        <div class="email-rightbar mb-3">
            <div class="card">
                <div class="btn-toolbar p-3" role="toolbar">
                    <div class="btn-group me-2 mb-2 mb-sm-0">
                        <button type="button" class="btn btn-primary waves-light waves-effect"><i
                                class="fa fa-inbox"></i></button>
                        <button type="button" class="btn btn-primary waves-light waves-effect"><i
                                class="fa fa-exclamation-circle"></i></button>
                        <button type="button" class="btn btn-primary waves-light waves-effect" (click)="confirm()"><i
                                class="far fa-trash-alt"></i></button>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0" ngbDropdown> 
                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle"
                            ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-folder"></i> <i class="mdi mdi-chevron-down ms-1"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Updates</a>
                            <a class="dropdown-item" href="javascript:void(0);">Social</a>
                            <a class="dropdown-item" href="javascript:void(0);">Team Manage</a>
                        </div>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0" ngbDropdown>
                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle"
                            ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-tag"></i> <i class="mdi mdi-chevron-down ms-1"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Updates</a>
                            <a class="dropdown-item" href="javascript:void(0);">Social</a>
                            <a class="dropdown-item" href="javascript:void(0);">Team Manage</a>
                        </div>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0" ngbDropdown>
                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle"
                            ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                            More <i class="mdi mdi-dots-vertical ms-2"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Mark as Unread</a>
                            <a class="dropdown-item" href="javascript:void(0);">Mark as Important</a>
                            <a class="dropdown-item" href="javascript:void(0);">Add to Tasks</a>
                            <a class="dropdown-item" href="javascript:void(0);">Add Star</a>
                            <a class="dropdown-item" href="javascript:void(0);">Mute</a>
                        </div>
                    </div>
                </div>
                <ul class="message-list">
                    <li class="unread" *ngFor="let email of emailData | slice:0:15; let i = index;"
                        [ngClass]="{ 'unread': email.unread === true }">
                        <div class="col-mail col-mail-1">
                            <div class="checkbox-wrapper-mail">
                                <input type="checkbox" id="chk-{{i}}" (change)="selectMail($event,email.id)" />
                                <label for="chk-{{i}}"></label>
                            </div>
                            <a [routerLink]="['../read',email.id]" class="title">{{email.title}}</a>
                            <span class="star-toggle far fa-star"></span>
                        </div>
                        <div class="col-mail col-mail-2">
                            <a [routerLink]="['../read',email.id]" class="subject">{{email.subject}}</a>
                            <div class="date">{{email.date}}</div>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- card -->
            <div class="row">
                <div class="col-7">
                    Showing {{startIndex}} - {{endIndex}} of {{totalRecords}}
                </div>
                <div class="col-5">
                    <div class="btn-group float-end">
                        <ngb-pagination class="pagination-rounded" [collectionSize]="totalRecords"
                            (pageChange)="onPageChange($event)" [(page)]="page" [pageSize]="pageSize">
                        </ngb-pagination>
                        <!--Pagination-->
                    </div>
                </div>
            </div>
        </div>
    </div><!-- end Col-9 -->
</div><!-- End row -->