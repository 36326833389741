<!-- start page title -->
<app-pagetitle title="UI Elements" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-12">

        <div class="d-xl-flex">

            <div class="w-100">
                <div>
                    <!-- Start Buttons -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="buttons">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Buttons</h4>
                                    <div>
                                        <h5 class="font-size-14">Basic</h5>
                                        <p class="card-title-desc">
                                            Bootstrap includes six predefined button styles, each serving its own
                                            semantic purpose..
                                        </p>
                                        <div class="button-items">
                                            <button type="button" class="btn btn-primary">Primary</button>
                                            <button type="button" class="btn btn-light waves-effect">Light</button>
                                            <button type="button" class="btn btn-success">Success</button>
                                            <button type="button" class="btn btn-info">Info</button>
                                            <button type="button" class="btn btn-warning">Warning</button>
                                            <button type="button" class="btn btn-danger">Danger</button>
                                            <button type="button" class="btn btn-dark">Dark</button>
                                            <button type="button" class="btn btn-link waves-effect">Link</button>
                                            <button type="button" class="btn btn-secondary">Secondary</button>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Outline</h5>
                                                <p class="card-title-desc">Replace the default modifier classes with the
                                                    <code class="highlighter-rouge">.btn-outline-*</code> ones to remove
                                                    all background images and colors on any button.
                                                </p>
                                                <div class="button-items">
                                                    <button type="button"
                                                        class="btn btn-outline-primary">Primary</button>
                                                    <button type="button"
                                                        class="btn btn-outline-secondary waves-effect">Secondary</button>
                                                    <button type="button"
                                                        class="btn btn-outline-success">Success</button>
                                                    <button type="button" class="btn btn-outline-info">Info</button>
                                                    <button type="button"
                                                        class="btn btn-outline-warning">Warning</button>
                                                    <button type="button" class="btn btn-outline-danger">Danger</button>
                                                    <button type="button" class="btn btn-outline-dark">Dark</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Rounded</h5>
                                                <p class="card-title-desc">Use class <code>.btn-rounded</code> for
                                                    button round.</p>
                                                <div class="button-items">
                                                    <button type="button"
                                                        class="btn btn-primary btn-rounded">Primary</button>
                                                    <button type="button"
                                                        class="btn btn-secondary btn-rounded">Secondary</button>
                                                    <button type="button"
                                                        class="btn btn-success btn-rounded">Success</button>
                                                    <button type="button" class="btn btn-info btn-rounded">Info</button>
                                                    <button type="button"
                                                        class="btn btn-warning btn-rounded">Warning</button>
                                                    <button type="button"
                                                        class="btn btn-danger btn-rounded">Danger</button>
                                                    <button type="button" class="btn btn-dark btn-rounded">Dark</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Sizes</h5>
                                                <p class="card-title-desc">Add <code>.btn-lg</code> or
                                                    <code>.btn-sm</code> for additional sizes.
                                                </p>

                                                <div class="button-items">
                                                    <button type="button" class="btn btn-primary btn-lg">Large
                                                        button</button>
                                                    <button type="button" class="btn btn-warning btn-sm">Small
                                                        button</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Width</h5>
                                                <p class="card-title-desc">Add <code>.w-xs</code>, <code>.w-sm</code>,
                                                    <code>.w-md</code> and <code> .w-lg</code> class for additional
                                                    buttons width.
                                                </p>
                                                <div class="button-items">
                                                    <button type="button" class="btn btn-primary w-xs">Xs</button>
                                                    <button type="button" class="btn btn-danger w-sm">Small</button>
                                                    <button type="button" class="btn btn-warning w-md">Medium</button>
                                                    <button type="button" class="btn btn-success w-lg">Large</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Tags</h5>
                                                <p class="card-title-desc">The <code>.btn</code>
                                                    classes are designed to be used with the <code>&lt;button&gt;</code>
                                                    element.
                                                    However, you can also use these classes on <code>&lt;a&gt;</code> or
                                                    <code>&lt;input&gt;</code> elements (though
                                                    some browsers may apply a slightly different rendering).
                                                </p>
                                                <div class="button-items">
                                                    <a class="btn btn-primary" href="#" role="button">Link</a>
                                                    <button class="btn btn-success" type="submit">Button</button>
                                                    <input class="btn btn-info" type="button" value="Input">
                                                    <input class="btn btn-danger" type="submit" value="Submit">
                                                    <input class="btn btn-warning" type="reset" value="Reset">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end Buttons -->

                    <!-- Start Badges -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="badges">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Badges</h4>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div>
                                                <h5 class="font-size-14">Basic</h5>
                                                <p class="card-title-desc">
                                                    Add any of the below mentioned modifier classes to change the
                                                    appearance of a badge.
                                                </p>
                                                <div>
                                                    <span class="badge bg-primary">Primary</span>
                                                    <span class="badge bg-success ms-1">Success</span>
                                                    <span class="badge bg-info ms-1">Info</span>
                                                    <span class="badge bg-warning ms-1">Warning</span>
                                                    <span class="badge bg-danger ms-1">Danger</span>
                                                    <span class="badge bg-dark ms-1">Dark</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mt-5 mt-lg-0">
                                                <h5 class="font-size-14">Pill</h5>
                                                <p class="card-title-desc">
                                                    Use the <code>.rounded-pill</code> modifier class to make badges
                                                    more rounded.
                                                </p>
                                                <div>
                                                    <span class="badge rounded-pill bg-primary">Primary</span>
                                                    <span class="badge rounded-pill bg-success ms-1">Success</span>
                                                    <span class="badge rounded-pill bg-info ms-1">Info</span>
                                                    <span class="badge rounded-pill bg-warning ms-1">Warning</span>
                                                    <span class="badge rounded-pill bg-danger ms-1">Danger</span>
                                                    <span class="badge rounded-pill bg-dark ms-1">Dark</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-5">
                                                <h5 class="font-size-14">Lighten</h5>
                                                <p class="card-title-desc">
                                                    Use <code>.bg-soft-*</code> class for a badge lighten.
                                                </p>
                                                <div>
                                                    <span class="badge badge-soft-primary">Primary</span>
                                                    <span class="badge badge-soft-success ms-1">Success</span>
                                                    <span class="badge badge-soft-info ms-1">Info</span>
                                                    <span class="badge badge-soft-warning ms-1">Warning</span>
                                                    <span class="badge badge-soft-danger ms-1">Danger</span>
                                                    <span class="badge badge-soft-dark ms-1">Dark</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-5">
                                                <h5 class="font-size-14">Soft Pill</h5>
                                                <p class="card-title-desc">
                                                    Use <code>.bg-soft-*</code> class for a pill badge.
                                                </p>
                                                <div>
                                                    <span class="badge rounded-pill badge-soft-primary">Primary</span>
                                                    <span class="badge rounded-pill badge-soft-success ms-1">Success</span>
                                                    <span class="badge rounded-pill badge-soft-info ms-1">Info</span>
                                                    <span class="badge rounded-pill badge-soft-warning ms-1">Warning</span>
                                                    <span class="badge rounded-pill badge-soft-danger ms-1">Danger</span>
                                                    <span class="badge rounded-pill badge-soft-dark ms-1">Dark</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-5">
                                                <h5 class="font-size-14">Badges in Buttons</h5>
                                                <p class="card-title-desc">Badges can be used as part of links or
                                                    buttons to provide a counter.</p>
                                                <div class="button-items">
                                                    <button type="button" class="btn btn-primary">
                                                        Notifications <span class="badge bg-success ms-1">4</span>
                                                    </button>
                                                    <button type="button" class="btn btn-success">
                                                        Messages <span class="badge bg-danger ms-1">2</span>
                                                    </button>
                                                    <button type="button" class="btn btn-outline-secondary">
                                                        Draft <span class="badge bg-success ms-1">2</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mt-5">
                                                <h5 class="font-size-14">Badges Position Examples</h5>
                                                <p class="card-title-desc">Example of Badges Position</p>
                                                <div class="d-flex flex-wrap gap-3">
                                                    <button type="button" class="btn btn-primary position-relative">
                                                        Mails <span
                                                            class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success">+99
                                                            <span class="visually-hidden">unread messages</span></span>
                                                    </button>

                                                    <button type="button" class="btn btn-light position-relative">
                                                        Alerts <span
                                                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1"><span
                                                                class="visually-hidden">unread messages</span></span>
                                                    </button>

                                                    <button type="button"
                                                        class="btn btn-primary position-relative p-0 avatar-xs rounded">
                                                        <span class="avatar-title bg-transparent">
                                                            <i class="bx bxs-envelope"></i>
                                                        </span>
                                                        <span
                                                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1"><span
                                                                class="visually-hidden">unread messages</span></span>
                                                    </button>

                                                    <button type="button"
                                                        class="btn btn-light position-relative p-0 avatar-xs rounded-circle">
                                                        <span class="avatar-title bg-transparent text-reset">
                                                            <i class="bx bxs-bell"></i>
                                                        </span>
                                                    </button>

                                                    <button type="button"
                                                        class="btn btn-light position-relative p-0 avatar-xs rounded-circle">
                                                        <span class="avatar-title bg-transparent text-reset">
                                                            <i class="bx bx-menu"></i>
                                                        </span>
                                                        <span
                                                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1"><span
                                                                class="visually-hidden">unread messages</span></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End Badges -->

                    <!-- Start Dropdown -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="dropdowns">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Dropdowns</h4>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="">
                                                <h5 class="font-size-14">Basic</h5>
                                                <p class="card-title-desc">
                                                    Any single <code class="highlighter-rouge">.btn</code> can be turned
                                                    into a dropdown
                                                    toggle with some markup changes. Here’s how you can put them to work
                                                    with either <code class="highlighter-rouge">&lt;button&gt;</code>
                                                    elements:
                                                </p>

                                                <div class="row">
                                                    <div class="col-sm-6">
                                                        <div class="dropdown" ngbDropdown>
                                                            <button class="btn btn-secondary dropdown-toggle"
                                                                type="button" ngbDropdownToggle id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                Dropdown Button <i class="mdi mdi-chevron-down"></i>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton" ngbDropdownMenu>
                                                                <a class="dropdown-item"
                                                                    href="javascript:void(0)">Action</a>
                                                                <a class="dropdown-item"
                                                                    href="javascript:void(0)">Another action</a>
                                                                <a class="dropdown-item"
                                                                    href="javascript:void(0)">Something else here</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6">
                                                        <div class="dropdown mt-4 mt-sm-0" ngbDropdown>
                                                            <a href="javascript:void(0)"
                                                                class="btn btn-secondary dropdown-toggle"
                                                                ngbDropdownToggle data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Dropdown link <i class="mdi mdi-chevron-down"></i>
                                                            </a>

                                                            <div class="dropdown-menu" ngbDropdownMenu>
                                                                <a class="dropdown-item"
                                                                    href="javascript:void(0)">Action</a>
                                                                <a class="dropdown-item"
                                                                    href="javascript:void(0)">Another action</a>
                                                                <a class="dropdown-item"
                                                                    href="javascript:void(0)">Something else here</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mt-4 mt-lg-0">
                                                <h5 class="font-size-14">Variant</h5>
                                                <p class="card-title-desc">The best part is you can do this with any
                                                    button variant, too:</p>

                                                <div class="d-flex gap-2 flex-wrap">
                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-primary dropdown-toggle"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">Primary <i
                                                                class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-secondary dropdown-toggle"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">Secondary <i
                                                                class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-success dropdown-toggle"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">Success <i
                                                                class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-info dropdown-toggle"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">Info <i
                                                                class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-warning dropdown-toggle"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">Warning <i
                                                                class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-danger dropdown-toggle"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">Danger <i
                                                                class="mdi mdi-chevron-down"></i></button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Split Button</h5>
                                                <p class="card-title-desc">The best part is you can do this with any
                                                    button variant, too:</p>

                                                <div class="d-flex gap-2 flex-wrap">
                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-primary">Primary</button>
                                                        <button type="button"
                                                            class="btn btn-primary dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button"
                                                            class="btn btn-secondary">Secondary</button>
                                                        <button type="button"
                                                            class="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-success">Success</button>
                                                        <button type="button"
                                                            class="btn btn-success dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-info">Info</button>
                                                        <button type="button"
                                                            class="btn btn-info dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-warning">Warning</button>
                                                        <button type="button"
                                                            class="btn btn-warning dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-danger">Danger</button>
                                                        <button type="button"
                                                            class="btn btn-danger dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div><!-- /btn-group -->

                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Sizing</h5>
                                                <p class="card-title-desc">Button dropdowns work with buttons of
                                                    all sizes, including default and split dropdown buttons.</p>

                                                <div class="">
                                                    <!-- Large button groups (default and split) -->
                                                    <div class="btn-group me-1 mt-2" ngbDropdown>
                                                        <button class="btn btn-primary btn-lg dropdown-toggle"
                                                            type="button" ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            Large Button <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div>
                                                    <div class="btn-group me-1 mt-2" ngbDropdown>
                                                        <button class="btn btn-secondary btn-lg" type="button">
                                                            Large Button
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-lg btn-secondary dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div>

                                                    <!-- Small button groups (default and split) -->
                                                    <div class="btn-group me-1 mt-2" ngbDropdown>
                                                        <button class="btn btn-info btn-sm dropdown-toggle"
                                                            type="button" ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            Small Button <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div>
                                                    <div class="btn-group me-1 mt-2" ngbDropdown>
                                                        <button class="btn btn-secondary btn-sm" type="button">
                                                            Small Button
                                                        </button>
                                                        <button type="button"
                                                            class="btn btn-sm btn-secondary dropdown-toggle dropdown-toggle-split"
                                                            ngbDropdownToggle data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <a class="dropdown-item"
                                                                href="javascript:void(0)">Action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Another
                                                                action</a>
                                                            <a class="dropdown-item" href="javascript:void(0)">Something
                                                                else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript:void(0)">Separated
                                                                link</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Dark Dropdowns</h5>
                                                <p class="card-title-desc">Opt into darker dropdowns to match a dark navbar or custom style by adding <code>.dropdown-menu-dark</code> onto an existing <code>.dropdown-menu</code>. No changes are required to the dropdown items.</p>
                
                                                <div class="dropdown" ngbDropdown>
                                                    <button class="btn btn-secondary dropdown-toggle" ngbDropdownToggle type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Dropdown button <i class="mdi mdi-chevron-down"></i>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-dark" ngbDropdownMenu aria-labelledby="dropdownMenuButton2" >
                                                        <li><a class="dropdown-item active" href="#">Action</a></li>
                                                        <li><a class="dropdown-item" href="javascript: void(0);">Another action</a></li>
                                                        <li><a class="dropdown-item" href="javascript: void(0);">Something else here</a></li>
                                                        <li>
                                                            <hr class="dropdown-divider">
                                                        </li>
                                                        <li><a class="dropdown-item" href="javascript: void(0);">Separated link</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div><!-- End col -->

                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Menu Alignment</h5>
                                                <p class="card-title-desc">Add <code class="highlighter-rouge">.dropdown-menu-end</code>
                                                    to a <code class="highlighter-rouge">.dropdown-menu</code> to right
                                                    align the dropdown menu.</p>
                
                                                <div class="btn-group" ngbDropdown>
                                                    <button type="button" class="btn btn-info dropdown-toggle" ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                                                        Right-aligned menu example <i class="mdi mdi-chevron-down"></i>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                                                        <li><button class="dropdown-item" type="button">Action</button></li>
                                                        <li><button class="dropdown-item" type="button">Another action</button></li>
                                                        <li><button class="dropdown-item" type="button">Something else here</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div><!-- End col -->
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Menu Content</h5>
                                                <p class="card-title-desc">Example of dropdown menu Headers, Text, Forms content</p>

                                                <div class="d-flex flex-wrap gap-2">
                                                    <!-- Header -->
                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Header <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu" ngbDropdownMenu>
                                                            <div class="dropdown-header noti-title">
                                                                <h5 class="font-size-13 text-muted text-truncate mn-0">Welcome Jessie!</h5>
                                                            </div>
                                                            <!-- item-->
                                                            <a class="dropdown-item" href="javascript: void(0);">Action</a>
                                                            <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                                                            <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                                                            <div class="dropdown-divider"></div>
                                                            <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
                                                        </div>
                                                    </div>
                                                
                                                    <!-- Text -->
                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-success dropdown-toggle" ngbDropdownToggle data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Text <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-md p-3" ngbDropdownMenu>
                                                            <p>
                                                                Some example text that's free-flowing within the dropdown menu.
                                                            </p>
                                                            <p class="mb-0">
                                                                And this is more example text.
                                                            </p>
                                                        </div>
                                                    </div>
                                                
                                                    <!-- Forms -->
                                                    <div class="btn-group" ngbDropdown>
                                                        <button type="button" class="btn btn-light dropdown-toggle" ngbDropdownToggle data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            Forms <i class="mdi mdi-chevron-down"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-md p-4" ngbDropdownMenu>
                                                            <form>
                                                                <div class="mb-2">
                                                                    <label class="form-label" for="exampleDropdownFormEmail">Email address</label>
                                                                    <input type="email" class="form-control" id="exampleDropdownFormEmail" placeholder="email@example.com">
                                                                </div>
                                                                <div class="mb-2">
                                                                    <label class="form-label" for="exampleDropdownFormPassword">Password</label>
                                                                    <input type="password" class="form-control" id="exampleDropdownFormPassword" placeholder="Password">
                                                                </div>
                                                                <div class="mb-2">
                                                                    <div class="form-check custom-checkbox">
                                                                        <input type="checkbox" class="form-check-input" id="rememberdropdownCheck">
                                                                        <label class="form-check-label" for="rememberdropdownCheck">Remember me</label>
                                                                    </div>
                                                                </div>
                                                                <button type="submit" class="btn btn-primary">Sign in</button>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- End col -->

                                    </div><!-- End row -->

                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end Dropdowns -->

                    <!-- Start Images -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="images">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Images</h4>
                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div class="">
                                                <h5 class="font-size-14">Rounded & Circle</h5>
                                                <p class="card-title-desc">Use classes
                                                    <code>.rounded</code> and <code>.rounded-circle</code>.
                                                </p>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <img class="rounded me-2" alt="200x200" width="200"
                                                            src="assets/images/small/img-4.jpg"
                                                            data-holder-rendered="true">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="mt-4 mt-md-0">
                                                            <img class="rounded-circle avatar-xl" alt="200x200"
                                                                src="assets/images/users/avatar-4.jpg"
                                                                data-holder-rendered="true">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="mt-4 mt-lg-0">
                                                <h5 class="font-size-14">Thumbnails</h5>
                                                <p class="card-title-desc">In addition to our border-radius utilities,
                                                    you can use
                                                    <code class="highlighter-rouge">.img-thumbnail</code> to give an
                                                    image a
                                                    rounded 1px border appearance.
                                                </p>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <img class="img-thumbnail" alt="200x200" width="200"
                                                            src="assets/images/small/img-3.jpg"
                                                            data-holder-rendered="true">
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="mt-4 mt-md-0">
                                                            <img class="img-thumbnail rounded-circle avatar-xl"
                                                                alt="200x200" src="assets/images/users/avatar-3.jpg"
                                                                data-holder-rendered="true">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->

                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <h5 class="font-size-14 mb-4">Sizes</h5>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-lg-4">
                                                            <div>
                                                                <img src="assets/images/users/avatar-3.jpg" alt=""
                                                                    class="rounded avatar-sm">
                                                                <p class="mt-2 mb-lg-0"><code>.avatar-sm</code></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div>
                                                                <img src="assets/images/users/avatar-4.jpg" alt=""
                                                                    class="rounded avatar-md">
                                                                <p class="mt-2  mb-lg-0"><code>.avatar-md</code></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div>
                                                                <img src="assets/images/users/avatar-5.jpg" alt=""
                                                                    class="rounded avatar-lg">
                                                                <p class="mt-2 mb-md-0"><code>.avatar-lg</code></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="row">
                                                        <div class="col-lg-4">
                                                            <div>
                                                                <img src="assets/images/users/avatar-3.jpg" alt=""
                                                                    class="rounded-circle avatar-sm">
                                                                <p class="mt-2 mb-lg-0"><code>.avatar-sm</code></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div>
                                                                <img src="assets/images/users/avatar-4.jpg" alt=""
                                                                    class="rounded-circle avatar-md">
                                                                <p class="mt-2  mb-lg-0"><code>.avatar-md</code></p>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-4">
                                                            <div>
                                                                <img src="assets/images/users/avatar-5.jpg" alt=""
                                                                    class="rounded-circle avatar-lg">
                                                                <p class="mt-2 mb-0"><code>.avatar-lg</code></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->

                    <!-- Start cards -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="cards">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Cards</h4>

                                    <div class="row">
                                        <div class="col-md-6 col-xl-3">
                                            <!-- Simple card -->
                                            <div class="card border">
                                                <img class="card-img-top img-fluid rounded-top"
                                                    src="assets/images/small/img-1.jpg" alt="Card image cap">
                                                <div class="card-body">
                                                    <h5 class="font-size-16 mt-0">Card title</h5>
                                                    <p class="card-text">Some quick example text to build on the card
                                                        title and make
                                                        up the bulk of the card's content.</p>
                                                    <a href="javascript:void(0)" class="btn btn-primary">Button</a>
                                                </div>
                                            </div>

                                        </div><!-- end col -->

                                        <div class="col-md-6 col-xl-3">
                                            <div class="card border">
                                                <img class="card-img-top img-fluid rounded-top"
                                                    src="assets/images/small/img-2.jpg" alt="Card image cap">
                                                <div class="card-body">
                                                    <h5 class="font-size-16 mt-0">Card title</h5>
                                                    <p class="card-text">Some quick example text to build on the card
                                                        title and make
                                                        up the bulk of the card's content.</p>
                                                </div>
                                                <ul class="list-group list-group-flush">
                                                    <li class="list-group-item text-truncate">Cras justo odio</li>
                                                    <li class="list-group-item text-truncate">Dapibus ac facilisis in
                                                    </li>
                                                </ul>
                                                <div class="card-body">
                                                    <a href="javascript:void(0)" class="card-link">Card link</a>
                                                    <a href="javascript:void(0)" class="card-link">Another link</a>
                                                </div>
                                            </div>

                                        </div><!-- end col -->

                                        <div class="col-xl-6">
                                            <div class="card border overflow-hidden">
                                                <div class="row no-gutters">
                                                    <div class="col-md-6">
                                                        <img class="card-img img-fluid"
                                                            src="assets/images/small/img-3.jpg" alt="Card image">
                                                    </div>
                                                    <div class="col-md-6 align-self-center">
                                                        <div class="card-body">
                                                            <h5 class="font-size-16 mt-0">Card title</h5>
                                                            <p class="card-text">This is a wider card with as a to
                                                                additional content.</p>
                                                            <p class="card-text"><small class="text-muted">Last updated
                                                                    3 mins ago</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="card border overflow-hidden">
                                                <div class="row no-gutters">
                                                    <div class="col-md-6 align-self-center">
                                                        <div class="card-body">
                                                            <h5 class="font-size-16 mt-0">Card title</h5>
                                                            <p class="card-text">This is a wider card with as a to
                                                                additional content.</p>
                                                            <p class="card-text"><small class="text-muted">Last updated
                                                                    3 mins ago</small></p>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <img class="card-img img-fluid"
                                                            src="assets/images/small/img-4.jpg" alt="Card image">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="card bg-primary text-white-50">
                                                <div class="card-body">
                                                    <h5 class="mt-0 mb-4 text-white text-truncate"><i
                                                            class="mdi mdi-bullseye-arrow me-3"></i> Primary Card</h5>
                                                    <p class="card-text">If several languages coalesce, the grammar of
                                                        the resulting individual</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <div class="card bg-success text-white-50">
                                                <div class="card-body">
                                                    <h5 class="mt-0 mb-4 text-white text-truncate"><i
                                                            class="mdi mdi-check-all me-3"></i> Success Card</h5>
                                                    <p class="card-text">If several languages coalesce, the grammar of
                                                        the resulting individual</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <div class="card bg-info text-white-50">
                                                <div class="card-body">
                                                    <h5 class="mt-0 mb-4 text-white text-truncate"><i
                                                            class="mdi mdi-alert-circle-outline me-3"></i>Info Card</h5>
                                                    <p class="card-text">If several languages coalesce, the grammar of
                                                        the resulting individual</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->

                                    <div class="row">
                                        <div class="col-lg-4">
                                            <div class="card border border-warning mb-lg-0">
                                                <div class="card-header bg-transparent border-warning">
                                                    <h5 class="my-0 text-warning text-truncate"><i
                                                            class="mdi mdi-alert me-3"></i>warning outline Card</h5>
                                                </div>
                                                <div class="card-body">
                                                    <h5 class="card-title mt-0">card title</h5>
                                                    <p class="card-text">If several languages coalesce, the grammar of
                                                        the resulting individual</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <div class="card border border-danger mb-lg-0">
                                                <div class="card-header bg-transparent border-danger">
                                                    <h5 class="my-0 text-danger text-truncate"><i
                                                            class="mdi mdi-block-helper me-3"></i>Danger outline Card
                                                    </h5>
                                                </div>
                                                <div class="card-body">
                                                    <h5 class="card-title mt-0">card title</h5>
                                                    <p class="card-text">If several languages coalesce, the grammar of
                                                        the resulting individual</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-4">
                                            <div class="card border border-success mb-0">
                                                <div class="card-header bg-transparent border-success">
                                                    <h5 class="my-0 text-success text-truncate"><i
                                                            class="mdi mdi-check-all me-3"></i>Success Card</h5>
                                                </div>
                                                <div class="card-body">
                                                    <h5 class="card-title mt-0">card title</h5>
                                                    <p class="card-text">If several languages coalesce, the grammar of
                                                        the resulting individual</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end cards -->

                    <!-- Start Collapse -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="collapse">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Collapse</h4>

                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div>
                                                <h5 class="font-size-14">Basic Collapse</h5>
                                                <p class="card-title-desc">
                                                    You can use a <code>ngbCollapse</code> directive, with the <code>href</code> or a button.
                                                </p>

                                                <p class="d-flex flex-wrap gap-2">
                                                    <a class="btn btn-primary" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed" href="javascript:void(0);">
                                                        Link with href
                                                    </a>
                                                    <button class="btn btn-primary" type="button" data-bs-target="#collapseExample" (click)="isCollapsed = !isCollapsed"
                                                        aria-expanded="false" >
                                                        Button with data-bs-target
                                                    </button>
                                                </p>
                                                <div class="collapse" id="collapseExample" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                                                    <div class="card card-body">
                                                        Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user
                                                        activates the relevant trigger.
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- End col -->
                                    </div><!-- End row -->
                                </div><!-- End card-body -->
                            </div><!-- End card -->
                        </div><!-- End col -->
                    </div><!-- End row -->
                    <!-- End Collapse -->

                    <!-- Start Tabs & Accordions -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="tabs-accordions">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Tabs & Accordions</h4>

                                    <div class="row">
                                        <div class="col-xl-6">
                                            <div>
                                                <h5 class="font-size-14">Basic Tabs</h5>
                                                <p class="card-title-desc">Example of Default Nav Tabs</p>
                                                <ul ngbNav #nav="ngbNav" [activeId]="1" class="nav-tabs">
                                                    <li [ngbNavItem]="1">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="fas fa-home"></i></span>
                                                            <span class="d-none d-sm-block">Home</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="2">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="far fa-user"></i></span>
                                                            <span class="d-none d-sm-block">Profile</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="3">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="far fa-envelope"></i></span>
                                                            <span class="d-none d-sm-block">Messages</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="4">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="fas fa-cog"></i></span>
                                                            <span class="d-none d-sm-block">Settings</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                </ul>
                                                <div [ngbNavOutlet]="nav" class="text-muted"></div>
                                            </div>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="mt-4 mt-xl-0">
                                                <h5 class="font-size-14">Basic Nav Pills</h5>
                                                <p class="card-title-desc">Example of Default Nav Pills</p>

                                                <!-- Nav tabs -->

                                                <ul ngbNav #nav2="ngbNav" [activeId]="1" class="nav-pills">
                                                    <li [ngbNavItem]="1">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="fas fa-home"></i></span>
                                                            <span class="d-none d-sm-block">Home</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="2">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="far fa-user"></i></span>
                                                            <span class="d-none d-sm-block">Profile</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="3">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="far fa-envelope"></i></span>
                                                            <span class="d-none d-sm-block">Messages</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="4">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="fas fa-cog"></i></span>
                                                            <span class="d-none d-sm-block">Settings</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                </ul>
                                                <div [ngbNavOutlet]="nav2" class="text-muted"></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-xl-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Custom Nav Tabs</h5>
                                                <p class="card-title-desc">Example of Custom Nav Tabs</p>
                                                <ul ngbNav #nav3="ngbNav" [activeId]="1"
                                                    class="nav-tabs nav-tabs-custom nav-justified">
                                                    <li [ngbNavItem]="1">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="fas fa-home"></i></span>
                                                            <span class="d-none d-sm-block">Home</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="2">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="far fa-user"></i></span>
                                                            <span class="d-none d-sm-block">Profile</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="3">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="far fa-envelope"></i></span>
                                                            <span class="d-none d-sm-block">Messages</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                    <li [ngbNavItem]="4">
                                                        <a ngbNavLink>
                                                            <span class="d-block d-sm-none"><i
                                                                    class="fas fa-cog"></i></span>
                                                            <span class="d-none d-sm-block">Settings</span>
                                                        </a>
                                                        <ng-template ngbNavContent>
                                                            <ng-template [ngTemplateOutlet]="TabContent"></ng-template>
                                                        </ng-template>
                                                    </li>
                                                </ul>
                                                <div [ngbNavOutlet]="nav3" class="text-muted"></div>
                                            </div>
                                        </div>

                                        <div class="col-xl-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Accordions</h5>
                                                <p class="card-title-desc">Extend the default collapse behavior to
                                                    create an accordion.</p>
                                                <div class="accordion" id="accordionExample">
                                                    <ngb-accordion #acc="ngbAccordion" activeIds="config-panel-one"
                                                        [closeOthers]=true>
                                                        <div class="card rounded mb-3"></div>
                                                        <ngb-panel title="Collapsible Group Item #1"
                                                            id="config-panel-one">
                                                            <ng-template ngbPanelContent>
                                                                Anim pariatur cliche reprehenderit, enim eiusmod high
                                                                life
                                                                accusamus terry richardson ad squid. 3 wolf moon officia
                                                                aute, non cupidatat skateboard dolor brunch. Food truck
                                                                quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                                                tempor,
                                                                nulla assumenda shoreditch et.
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel title="Collapsible Group Item #2"
                                                            id="config-panel-two">
                                                            <ng-template ngbPanelContent>
                                                                sunt aliqua put a bird on it squid single-origin coffee
                                                                nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                                helvetica, craft beer labore wes anderson cred nesciunt
                                                                Leggings occaecat craft beer farm-to-table, raw denim
                                                                accusamus labore sustainable VHS.
                                                            </ng-template>
                                                        </ngb-panel>
                                                        <ngb-panel title="Collapsible Group Item #3"
                                                            id="config-panel-three">
                                                            <ng-template ngbPanelContent>
                                                                Anim pariatur cliche reprehenderit, enim eiusmod high
                                                                life
                                                                accusamus terry richardson ad squid. 3 wolf moon officia
                                                                aute, non cupidatat skateboard dolor brunch. Food truck
                                                                quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
                                                                tempor,
                                                                nulla assumenda shoreditch et.
                                                            </ng-template>
                                                        </ngb-panel>
                                                    </ngb-accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end Tabs & Accordions -->

                    <!-- Start Modals -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="modals">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Modals</h4>
                                    <div>
                                        <div>
                                            <h5 class="font-size-14">Basic</h5>
                                            <p class="card-title-desc">Toggle a working modal demo by clicking the
                                                button below. It will slide down and fade in from the top of the page.
                                            </p>
                                            <button type="button" class="btn btn-primary"
                                                (click)="openModal(content)">Standard
                                                modal</button>
                                            <!-- sample modal content -->
                                            <!-- Standard Modal -->
                                            <ng-template #content role="document" let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title mt-0">Modal Heading</h5>
                                                    <button type="button" class="btn-close" aria-hidden="true"
                                                        (click)="modal.dismiss('Cross click')"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <h5 class="font-size-16">Overflowing text to show scroll
                                                        behavior</h5>
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Vivamus sagittis lacus vel
                                                        augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur.
                                                        Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Donec sed odio dui. Donec
                                                        ullamcorper nulla non metus auctor
                                                        fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Vivamus sagittis lacus vel
                                                        augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur.
                                                        Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Donec sed odio dui. Donec
                                                        ullamcorper nulla non metus auctor
                                                        fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-light"
                                                        (click)="modal.close('Close click')">Close</button>
                                                    <button type="button" class="btn btn-primary">Save changes</button>
                                                </div>
                                            </ng-template>
                                        </div>


                                        <div class="mt-4 pt-2">
                                            <h5 class="font-size-14">Optional Sizes</h5>
                                            <p class="card-title-desc">Modals have three optional sizes, available via
                                                modifier classes to be placed on a <code>.modal-dialog</code>.</p>
                                            <div class="button-items">
                                                <!-- Extra Large modal -->
                                                <button type="button" class="btn btn-primary"
                                                    (click)="extraLarge(exlargeModal)">Extra large
                                                    modal</button>
                                                <!-- Large modal -->
                                                <button type="button" class="btn btn-success"
                                                    (click)="largeModal(largeDataModal)">Large modal</button>
                                                <!-- Small modal -->
                                                <button type="button" class="btn btn-danger"
                                                    (click)="smallModal(smallDataModal)">Small
                                                    modal</button>
                                                <!-- Full Screen  modal -->
                                                <button type="button" class="btn btn-info"
                                                    (click)="fullModal(fullDataModal)">Full Screen</button>
                                            </div>

                                            <!--  Extra Large modal example -->
                                            <!-- Extra Large Modal -->
                                            <ng-template #exlargeModal let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title mt-0">Extra large modal </h5>
                                                    <button type="button" class="btn-close" aria-label="Close"
                                                        (click)="modal.dismiss('Close click')">
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Vivamus sagittis lacus vel
                                                        augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                                        Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Donec sed odio dui. Donec
                                                        ullamcorper nulla non metus auctor
                                                        fringilla.</p>
                                                </div>
                                            </ng-template>

                                            <!--  Large modal example -->
                                            <ng-template #largeDataModal let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title mt-0">Large modal</h5>
                                                    <button type="button" class="btn-close"
                                                        (click)="modal.dismiss('Cross click')"
                                                        aria-hidden="true"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Vivamus sagittis lacus vel
                                                        augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                                        Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Donec sed odio dui. Donec
                                                        ullamcorper nulla non metus auctor
                                                        fringilla.</p>
                                                </div>
                                            </ng-template>

                                            <!--  Small modal example -->
                                            <ng-template #smallDataModal let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title mt-0">Small modal</h5>
                                                    <button type="button" class="btn-close"
                                                        (click)="modal.dismiss('Cross click')"
                                                        aria-hidden="true"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Vivamus sagittis lacus vel
                                                        augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                                        Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Donec sed odio dui. Donec
                                                        ullamcorper nulla non metus auctor
                                                        fringilla.</p>
                                                </div>
                                            </ng-template>

                                            <!--  Full Screen  modal example -->
                                            <ng-template #fullDataModal let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title mt-0">Small modal</h5>
                                                    <button type="button" class="btn-close"
                                                        (click)="modal.dismiss('Cross click')"
                                                        aria-hidden="true"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Vivamus sagittis lacus vel
                                                        augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                                        Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Donec sed odio dui. Donec
                                                        ullamcorper nulla non metus auctor
                                                        fringilla.</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-light"
                                                        (click)="modal.close('Close click')">Close</button>
                                                    <button type="button" class="btn btn-primary">Save changes</button>
                                                </div>
                                            </ng-template>
                                        </div>

                                    </div>
                                    <!-- end row -->

                                    <div class="row mt-3">
                                        <div class="col-xl-4 col-md-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Vertically Centered</h5>
                                                <p class="card-title-desc">Add <code>.modal-dialog-centered</code> to
                                                    <code>.modal-dialog</code> to vertically center the modal.
                                                </p>
                                            </div>

                                            <!-- center modal -->
                                            <button type="button" class="btn btn-primary"
                                                (click)="centerModal(centerDataModal)">Center modal</button>
                                            <ng-template #centerDataModal let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title mt-0">Center modal</h5>
                                                    <button type="button" class="btn-close"
                                                        (click)="modal.dismiss('Cross click')"
                                                        aria-hidden="true"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum.
                                                        Cras justo odio, dapibus ac facilisis in,
                                                        egestas eget quam. Morbi leo risus, porta ac
                                                        consectetur ac, vestibulum at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Vivamus sagittis lacus vel
                                                        augue laoreet rutrum faucibus dolor auctor.</p>
                                                    <p class="mb-0">Aenean lacinia bibendum nulla sed consectetur.
                                                        Praesent commodo cursus magna, vel scelerisque
                                                        nisl consectetur et. Donec sed odio dui. Donec
                                                        ullamcorper nulla non metus auctor
                                                        fringilla.</p>
                                                </div>
                                            </ng-template>
                                        </div>
                                        <div class="col-xl-4 col-md-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Scrollable</h5>
                                                <p class="card-title-desc">You can also create a scrollable modal that
                                                    allows scroll the modal body by adding
                                                    <code>.modal-dialog-scrollable</code> to <code>.modal-dialog</code>.
                                                </p>
                                                
                                                <!-- Scrollable modal -->
                                                <button type="button" class="btn btn-primary ms-1"
                                                    (click)="scrollModal(scrollDataModal)">Scrollable modal</button>
                                            </div>

                                            <ng-template #scrollDataModal let-modal>
                                                <div class="modal-header">
                                                    <h5 class="modal-title mt-0">Scrollable Modal</h5>
                                                    <button type="button" class="btn-close"
                                                        (click)="modal.dismiss('Cross click')" aria-label="Close">
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                                                        odio, dapibus ac facilisis in, egestas
                                                        eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                                                        at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                                                        et. Vivamus sagittis lacus vel augue
                                                        laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                                                        cursus magna, vel scelerisque nisl
                                                        consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                                                        metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                                                        odio, dapibus ac facilisis in, egestas
                                                        eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                                                        at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                                                        et. Vivamus sagittis lacus vel augue
                                                        laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                                                        cursus magna, vel scelerisque nisl
                                                        consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                                                        metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                                                        odio, dapibus ac facilisis in, egestas
                                                        eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                                                        at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                                                        et. Vivamus sagittis lacus vel augue
                                                        laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                                                        cursus magna, vel scelerisque nisl
                                                        consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                                                        metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                                                        odio, dapibus ac facilisis in, egestas
                                                        eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                                                        at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                                                        et. Vivamus sagittis lacus vel augue
                                                        laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                                                        cursus magna, vel scelerisque nisl
                                                        consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                                                        metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                                                        odio, dapibus ac facilisis in, egestas
                                                        eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                                                        at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                                                        et. Vivamus sagittis lacus vel augue
                                                        laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                                                        cursus magna, vel scelerisque nisl
                                                        consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                                                        metus auctor fringilla.</p>
                                                    <p>Cras mattis consectetur purus sit amet fermentum. Cras justo
                                                        odio, dapibus ac facilisis in, egestas
                                                        eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum
                                                        at eros.</p>
                                                    <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur
                                                        et. Vivamus sagittis lacus vel augue
                                                        laoreet rutrum faucibus dolor auctor.</p>
                                                    <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
                                                        cursus magna, vel scelerisque nisl
                                                        consectetur et. Donec sed odio dui. Donec ullamcorper nulla non
                                                        metus auctor fringilla.</p>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-secondary"
                                                        (click)="modal.close('Close click')">Close</button>
                                                    <button type="button" class="btn btn-primary">Save changes</button>
                                                </div>
                                            </ng-template>

                                        </div>

                                        <div class="col-xl-4 col-md-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Static Backdrop</h5>
                                                <p class="card-title-desc">When backdrop is set to static, the modal
                                                    will not close when clicking outside it. Click the button below to
                                                    try it.</p>

                                                <!-- Button trigger modal -->
                                                <button type="button" class="btn btn-primary"
                                                    (click)="staticModal(staticDataModal)">
                                                    Static backdrop modal
                                                </button>

                                                <!-- staticBackdrop Modal -->
                                                <ng-template #staticDataModal let-modal>
                                                    <div class="modal-header">
                                                        <h5 class="modal-title mt-0">Center modal</h5>
                                                        <button type="button" class="btn-close"
                                                            (click)="modal.dismiss('Cross click')"
                                                            aria-hidden="true"></button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <p>I will not close if you click outside me. Don't even try to
                                                            press escape key.</p>
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-light"
                                                            (click)="modal.close('Close click')">Close</button>
                                                        <button type="button"
                                                            class="btn btn-primary">Understood</button>
                                                    </div>
                                                </ng-template>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- end row -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->

                    <!-- start pagination -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card overflow-hidden" id="pagination">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Pagination</h4>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="">
                                                <h5 class="font-size-14">Basic</h5>
                                                <p class="card-title-desc">Pagination links indicate a series of related
                                                    content exists across multiple pages.</p>

                                                <nav aria-label="Page navigation example">
                                                    <ul class="pagination">
                                                        <li class="page-item disabled"><a class="page-link"
                                                                href="javascript:void(0)">Previous</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">1</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">2</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">3</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">Next</a></li>
                                                    </ul>
                                                </nav>

                                                <nav aria-label="Page navigation example">
                                                    <ul class="pagination mb-0">
                                                        <li class="page-item">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                aria-label="Previous">
                                                                &lt;
                                                            </a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">1</a></li>
                                                        <li class="page-item active"><a class="page-link"
                                                                href="javascript:void(0)">2</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                aria-label="Next">
                                                                &gt;
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-4 mt-lg-0">
                                                <h5 class="font-size-14">Custom</h5>
                                                <p class="card-title-desc">Add <code>.pagination-rounded</code> with
                                                    <code>.pagination</code> class for rounded pagination.
                                                </p>

                                                <nav aria-label="Page navigation example">
                                                    <ul class="pagination pagination-rounded mb-0">
                                                        <li class="page-item">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                aria-label="Previous">
                                                                <i class="mdi mdi-chevron-left"></i>
                                                            </a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">1</a></li>
                                                        <li class="page-item active"><a class="page-link"
                                                                href="javascript:void(0)">2</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                aria-label="Next">
                                                                <i class="mdi mdi-chevron-right"></i>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Sizing</h5>
                                                <p class="card-title-desc">Add <code>.pagination-lg</code> or
                                                    <code>.pagination-sm</code> for pagination additional
                                                    sizes.
                                                </p>

                                                <nav aria-label="...">
                                                    <ul class="pagination pagination-lg">
                                                        <li class="page-item disabled">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                tabindex="-1">Previous</a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">1</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">2</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="javascript:void(0)">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>

                                                <nav aria-label="...">
                                                    <ul class="pagination pagination-sm mb-0">
                                                        <li class="page-item disabled">
                                                            <a class="page-link" href="javascript:void(0)"
                                                                tabindex="-1">Previous</a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">1</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">2</a></li>
                                                        <li class="page-item"><a class="page-link"
                                                                href="javascript:void(0)">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="javascript:void(0)">Next</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end pagination -->

                    <!-- start Placeholder -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card overflow-hidden" id="placeholder">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Placeholder</h4>

                                    <div class="row">
                                        <div class="col-xl-12">
                                            <h5 class="font-size-14">Basic</h5>
                                            <p class="card-title-desc">Use loading placeholders for your components or pages to indicate something may still be loading</p>

                                            <div class="row ">
                                                <div class="col-xl-3 col-md-6">
                                                    <div class="card">
                                                        <img src="assets/images/small/img-1.jpg" class="card-img-top" alt="card img">
                                                      
                                                        <div class="card-body">
                                                          <h5 class="card-title">Card title</h5>
                                                          <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                                          <a href="#" class="btn btn-primary">Go somewhere</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-3 offset-xl-3 col-md-6">
                                                    <div class="card" aria-hidden="true">
                                                        <img src="assets/images/small/img-1.jpg" class="card-img-top" alt="card dummy img">
                                                        <div class="card-body">
                                                          <h5 class="card-title placeholder-glow">
                                                            <span class="placeholder col-6"></span>
                                                          </h5>
                                                          <p class="card-text placeholder-glow">
                                                            <span class="placeholder col-7"></span>
                                                            <span class="placeholder col-4"></span>
                                                            <span class="placeholder col-4"></span>
                                                            <span class="placeholder col-6"></span>
                                                          </p>
                                                          <a href="#" tabindex="-1" class="btn btn-primary disabled placeholder col-6"></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div>
                                                <div>
                                                    <h5 class="font-size-14">Width</h5>
                                                    <p class="card-title-desc">You can change the <code>width</code> through grid column classes, width utilities,
                                                        or
                                                        inline styles.</p>
                                                    <div class="">
                                                        <span class="placeholder col-6"></span>
                                                        <span class="placeholder w-75"></span>
                                                        <span class="placeholder" style="width: 25%;"></span>
                                                    </div>
                                                </div>

                                                <div class="mt-3">
                                                    <h5 class="font-size-14">Sizing</h5>
                                                    <p class="card-title-desc">The size of <code>.placeholder</code>s are based on the typographic style of the
                                                        parent
                                                        element. Customize them with sizing modifiers: <code>.placeholder-lg</code>, <code>.placeholder-sm</code>,
                                                        or
                                                        <code>.placeholder-xs</code>.
                                                    </p>
                                                    <div class="">
                                                        <span class="placeholder col-12 placeholder-lg"></span>
                                                        <span class="placeholder col-12"></span>
                                                        <span class="placeholder col-12 placeholder-sm"></span>
                                                        <span class="placeholder col-12 placeholder-xs"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- End col -->

                                        <div class="col-lg-6">
                                            <div class="mt-4 mt-lg-0">
                                                <h5 class="font-size-14">Color</h5>
                                                <p class="card-title-desc">By default, the <code>placeholder</code> uses <code>currentColor</code>. This can be overriden with a custom color or utility class.</p>
    
                                                <div class="">
                                                    <span class="placeholder col-12 mb-3"></span>
                                                    <span class="placeholder col-12 mb-3 bg-primary"></span>
                                                    <span class="placeholder col-12 mb-3 bg-secondary"></span>
                                                    <span class="placeholder col-12 mb-3 bg-success"></span>
                                                    <span class="placeholder col-12 mb-3 bg-danger"></span>
                                                    <span class="placeholder col-12 mb-3 bg-warning"></span>
                                                    <span class="placeholder col-12 mb-3 bg-info"></span>
                                                    <span class="placeholder col-12 mb-3 bg-light"></span>
                                                    <span class="placeholder col-12 bg-dark"></span>
                                                </div>
                                            </div>
                                        </div><!-- End col -->
                                    </div><!-- End row -->

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div>
                                                <h5 class="font-size-14">Placeholder Glow Animation</h5>
                                                <p class="card-title-desc">Animate placehodlers with <code>.placeholder-glow</code> to better convey the perception of something being <em>actively</em> loaded.</p>
                                    
                                                <div>
                                                    <div class="placeholder-glow">
                                                        <span class="placeholder col-12"></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div><!-- End col -->

                                        <div class="col-lg-6">
                                            <div class="mt-4 mt-lg-0">
                                                <h5 class="font-size-14">Placeholder Wave Animation</h5>
                                                <p class="card-title-desc">Animate placehodlers with  <code>.placeholder-wave</code> to better convey the perception of something being <em>actively</em> loaded.</p>

    
                                                <div class="placeholder-wave">
                                                    <span class="placeholder col-12"></span>
                                                </div>
                                            </div>
                                        </div><!-- End col -->
                                    </div><!-- End row -->
                                </div><!-- End card-body -->
                            </div><!-- End card -->
                        </div><!-- End col -->
                    </div><!-- End row -->
                    <!-- End Placeholder -->

                    <!-- start Progress -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="progress">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Progress</h4>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div>
                                                <h5 class="font-size-14">Basic</h5>
                                                <p class="card-title-desc">Progress components are built with two
                                                    HTML elements, some CSS to set the width, and a few attributes.</p>
                                                <div class="">
                                                    <p class="mb-4">
                                                        <ngb-progressbar [value]="25"></ngb-progressbar>
                                                    </p>
                                                    <p class="mb-4">
                                                        <ngb-progressbar type="warning" [value]="50"></ngb-progressbar>
                                                    </p>
                                                    <p class="mb-4">
                                                        <ngb-progressbar type="success" [value]="75"></ngb-progressbar>
                                                    </p>
                                                    <p class="mb-0">
                                                        <ngb-progressbar type="danger" [value]="100"></ngb-progressbar>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-4 mt-lg-0">
                                                <h5 class="font-size-14">Heights</h5>
                                                <p class="card-title-desc">We only set a <code>height</code> value on
                                                    the <code>.progress-bar</code>, so if you change
                                                    that value the outer <code>.progress</code>
                                                    will automatically resize accordingly.</p>
                                                <div class="">
                                                    <p class="mb-4">
                                                        <ngb-progressbar type="danger" [value]="25" height="5px">
                                                        </ngb-progressbar>
                                                    </p>
                                                    <p class="mb-4">
                                                        <ngb-progressbar [value]="47" height="8px"></ngb-progressbar>
                                                    </p>
                                                    <p class="mb-4">
                                                        <ngb-progressbar type="warning" [value]="75" height="15px">
                                                        </ngb-progressbar>
                                                    </p>
                                                    <p class="mb-4">
                                                        <ngb-progressbar type="info" [value]="64" height="20px">
                                                        </ngb-progressbar>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-12">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Striped & Animated</h5>
                                                <p class="card-title-desc">Add <code>.progress-bar-striped</code>
                                                    to any <code>.progress-bar</code> to apply a
                                                    stripe via CSS gradient over the progress bar’s background color.
                                                </p>
                                                <div class="">
                                                    <div class="mb-4">
                                                        <ngb-progressbar [value]="10" [striped]="true"
                                                            [animated]="true"></ngb-progressbar>
                                                    </div>
                                                    <div class="mb-4">
                                                        <ngb-progressbar [value]="25" [striped]="true" [animated]="true"
                                                            type="success"></ngb-progressbar>
                                                    </div>
                                                    <div class="mb-4">
                                                        <ngb-progressbar [value]="50" [striped]="true" [animated]="true"
                                                            type="info"></ngb-progressbar>
                                                    </div>
                                                    <div class="mb-4">
                                                        <ngb-progressbar [value]="75" [striped]="true" [animated]="true"
                                                            type="warning"></ngb-progressbar>
                                                    </div>
                                                    <div class="mb-4">
                                                        <ngb-progressbar [value]="100" [striped]="true"
                                                            [animated]="true" type="danger"></ngb-progressbar>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->

                    <!-- Start Tooltip - popover -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="tooltip-popover">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Tooltips & Popovers</h4>
                                    <div>
                                        <div>
                                            <h5 class="font-size-14">Tooltips</h5>
                                            <p class="card-title-desc">Hover over the links below to see tooltips.</p>
                                            <div class="button-items" id="tooltip-container">
                                                <button type="button" ngbTooltip="Tooltip on top"
                                                    class="btn btn-primary" placement="top">
                                                    Tooltip on top
                                                </button>

                                                <button type="button" class="btn btn-primary" placement="right"
                                                    ngbTooltip="Tooltip on right">
                                                    Tooltip on right
                                                </button>

                                                <button type="button" class="btn btn-primary" placement="bottom"
                                                    ngbTooltip="Tooltip on bottom">
                                                    Tooltip on bottom
                                                </button>

                                                <button type="button" class="btn btn-primary" placement="left"
                                                    ngbTooltip="Tooltip on left">
                                                    Tooltip on left
                                                </button>
                                            </div>
                                        </div>

                                        <div class="mt-4 pt-2">
                                            <h5 class="font-size-14">Popovers</h5>
                                            <p class="card-title-desc">Add small overlay content, like those found in
                                                iOS, to any element for housing secondary information.</p>
                                            <div class="button-items" id="popover-container">
                                                <button type="button" class="btn btn-secondary" placement="top"
                                                    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                                                    Popover on top
                                                </button>

                                                <button type="button" class="btn btn-secondary waves-effect"
                                                    placement="right" data-bs-placement="right"
                                                    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                                                    Popover on right
                                                </button>

                                                <button type="button" class="btn btn-secondary waves-effect"
                                                    placement="bottom" data-bs-placement="bottom"
                                                    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                                                    Popover on bottom
                                                </button>

                                                <button type="button" class="btn btn-secondary waves-effect"
                                                    placement="left" data-bs-placement="left" title="Popover Title"
                                                    ngbPopover="Vivamus sagittis lacus vel augue laoreet rutrum faucibus.">
                                                    Popover on left
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end Tooltip - popover -->

                    <!-- start spinner -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="spinner">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Spinners</h4>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div>
                                                <div>
                                                    <h5 class="font-size-14">Border</h5>
                                                    <p class="card-title-desc">Use the border spinners for a lightweight
                                                        loading indicator.</p>
                                                    <div>
                                                        <div class="spinner-border text-primary m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mt-4 pt-2">
                                                    <h5 class="font-size-14">Color variation</h5>
                                                    <p class="card-title-desc">Add <code>text-*</code> color for a
                                                        Spinner color variation.</p>
                                                    <div>
                                                        <div class="spinner-border text-primary m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div class="spinner-border text-secondary m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div class="spinner-border text-success m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div class="spinner-border text-info m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div class="spinner-border text-warning m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div class="spinner-border text-danger m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div class="spinner-border text-dark m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-4 mt-lg-0">
                                                <div>
                                                    <h5 class="font-size-14">Growing</h5>
                                                    <p class="card-title-desc">Switch to the grow spinner.it does
                                                        repeatedly grow Continue!</p>
                                                    <div>
                                                        <div class="spinner-grow text-primary m-1" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mt-4 pt-2">
                                                <h5 class="font-size-14">Color Variation</h5>
                                                <p class="card-title-desc">Add <code>text-*</code> color for a Spinner
                                                    color variation.</p>
                                                <div>
                                                    <div class="spinner-grow text-secondary m-1" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div class="spinner-grow text-success m-1" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div class="spinner-grow text-info m-1" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div class="spinner-grow text-warning m-1" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div class="spinner-grow text-danger m-1" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <div class="spinner-grow text-dark m-1" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end spinner -->

                    <!-- Start Grid -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="grid">
                                <div class="card-body">
                                    <h4 class="card-title">Grid</h4>
                                    <p class="card-title-desc mb-4">See how aspects of the Bootstrap grid
                                        system work across multiple devices with a handy table.</p>
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col" class="text-center">
                                                        xs<br>
                                                        <span class="fw-normal">&lt;576px</span>
                                                    </th>
                                                    <th scope="col" class="text-center">
                                                        sm<br>
                                                        <span class="fw-normal">≥576px</span>
                                                    </th>
                                                    <th scope="col" class="text-center">
                                                        md<br>
                                                        <span class="fw-normal">≥768px</span>
                                                    </th>
                                                    <th scope="col" class="text-center">
                                                        lg<br>
                                                        <span class="fw-normal">≥992px</span>
                                                    </th>
                                                    <th scope="col" class="text-center">
                                                        xl<br>
                                                        <span class="fw-normal">≥1200px</span>
                                                    </th>
                                                    <th scope="col" class="text-center">
                                                        xxl<br>
                                                        <span class="fw-normal">≥1400px</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Grid behavior</th>
                                                    <td>Horizontal at all times</td>
                                                    <td colspan="5">Collapsed to start, horizontal above breakpoints
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Max container width</th>
                                                    <td>None (auto)</td>
                                                    <td>540px</td>
                                                    <td>720px</td>
                                                    <td>960px</td>
                                                    <td>1140px</td>
                                                    <td>1320px</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Class prefix</th>
                                                    <td><code>.col-</code></td>
                                                    <td><code>.col-sm-</code></td>
                                                    <td><code>.col-md-</code></td>
                                                    <td><code>.col-lg-</code></td>
                                                    <td><code>.col-xl-</code></td>
                                                    <td><code>.col-xxl-</code></td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row"># of columns</th>
                                                    <td colspan="6">12</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Gutter width</th>
                                                    <td colspan="6">24px (12px on each side of a column)</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Custom gutters</th>
                                                    <td colspan="6">Yes</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Nestable</th>
                                                    <td colspan="6">Yes</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Offsets</th>
                                                    <td colspan="6">Yes</td>
                                                </tr>
                                                <tr>
                                                    <th class="text-nowrap" scope="row">Column ordering</th>
                                                    <td colspan="6">Yes</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <!-- end Grid -->


                    <!-- start Video -->
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card" id="video">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Video</h4>

                                    <div class="row">
                                        <div class="col-lg-6">
                                            <div>
                                                <h5 class="font-size-14">Responsive Embed Video 16:9</h5>
                                                <p class="card-title-desc">Aspect ratios can be customized with modifier
                                                    classes.</p>
                                                <div class="">
                                                    <div class="ratio ratio-16x9">
                                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                                            title="YouTube video" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-6">
                                            <div class="mt-4 mt-lg-0">
                                                <h5 class="font-size-14">Responsive Embed Video 21:9</h5>
                                                <p class="card-title-desc">Aspect ratios can be customized with modifier
                                                    classes.</p>
                                                <div class="">
                                                    <div class="ratio ratio-21x9">
                                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                                            title="YouTube video" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row mt-3">
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Responsive Embed Video 4:3</h5>
                                                <p class="card-title-desc">Aspect ratios can be customized with modifier
                                                    classes.</p>
                                                <div class="">
                                                    <div class="ratio ratio-4x3">
                                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                                            title="YouTube video" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-6">
                                            <div class="mt-4">
                                                <h5 class="font-size-14">Responsive Embed Video 1:1</h5>
                                                <p class="card-title-desc">Aspect ratios can be customized with modifier
                                                    classes.</p>
                                                <div class="">
                                                    <div class="ratio ratio-1x1">
                                                        <iframe src="https://www.youtube.com/embed/1y_kfWUCFDQ"
                                                            title="YouTube video" allowfullscreen></iframe>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- end row -->
                </div>
            </div>
            <!-- end content page -->

            <!-- Start right-side-nav -->
            <div class="d-none d-lg-block">
                <div class="ui-elements-demo-bar card">

                    <ul class="nav nav-pills flex-column">
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#buttons'" class="nav-link">Buttons</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#badges'" class="nav-link">Badges</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#dropdowns'" class="nav-link">Dropdowns</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#images'" class="nav-link">Images</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#cards'" class="nav-link">Cards</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#collapse'" class="nav-link">Collapse</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#tabs-accordions'" class="nav-link">Tabs &
                                Accordions</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#modals'" class="nav-link">Modals</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#pagination'" class="nav-link">Pagination</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#placeholder'" class="nav-link">Placeholder</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#progress'" class="nav-link">Progress</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#tooltip-popover'" class="nav-link">Tooltips
                                & Popovers</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#spinner'" class="nav-link">Spinners</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#grid'" class="nav-link">Grid</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript: void(0);" [ngxScrollTo]="'#video'" class="nav-link">Video</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- end row -->

<ng-template #TabContent>
    <p class="p-3 mb-0">
        Raw denim you probably haven't heard of them jean shorts Austin. Nesciunt tofu stumptown aliqua, retro synth
        master cleanse. Mustache cliche tempor, williamsburg carles vegan helvetica. Reprehenderit butcher retro
        keffiyeh dreamcatcher synth. Cosby sweater eu banh iphone. Seitan aliquip butcher voluptate nisi qui.
    </p>
</ng-template>