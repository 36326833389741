<!-- start page title -->
<app-pagetitle title="Maps" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Google Markers</h4>
                <p class="card-title-dsec">Example of google maps.</p>
                <agm-map [latitude]="latitude" [longitude]="longitude" style="height: 300px"></agm-map>
            </div>
        </div>
    </div> <!-- end col -->
    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Google Overlays</h4>
                <p class="card-title-desc">Example of google maps.</p>
                <agm-map [latitude]="latitude" [longitude]="longitude" style="height: 300px">
                    <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                </agm-map>
            </div>
        </div>
    </div> <!-- end col -->
</div> <!-- end row -->

<div class="row">

    <div class="col-lg-6">
        <div class="card">
            <div class="card-body">
                <h4 class="card-title">Map Types</h4>
                <p class="card-title-desc">Example of google maps.</p>
                <agm-map [latitude]="latitude" [longitude]="longitude" style="height: 300px">
                    <agm-circle [latitude]="latitude" [longitude]="longitude" [radius]="9000" [fillColor]="'red'"
                        [circleDraggable]="true" [editable]="true">
                    </agm-circle>
                </agm-map>
            </div>
        </div>
    </div> <!-- end col -->
</div> <!-- end row -->