<!-- start page title -->
<app-pagetitle title="Read Email" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
<!-- end page title -->

<div class="row">
    <div class="col-12">
        <!-- Left sidebar -->
        <div class="email-leftbar card">
            <button type="button" class="btn btn-danger btn-block" data-toggle="modal" data-target="#composemodal"
                (click)="open(content)">
                Compose
            </button>
            <div class="mail-list mt-4">
                <a href="javascript:void(0);" class="active"><i class="bx bxs-inbox font-size-16 align-middle me-2"></i>
                    Inbox <span class="ms-1 float-end">(18)</span></a>
                <a href="javascript:void(0);"><i class="bx bx-star font-size-16 align-middle me-2"></i>Starred</a>
                <a href="javascript:void(0);"><i
                        class="bx bxs-bookmark font-size-16 align-middle me-2"></i>Important</a>
                <a href="javascript:void(0);"><i class="bx bx-file font-size-16 align-middle me-2"></i>Draft</a>
                <a href="javascript:void(0);"><i class="bx bx-mail-send font-size-16 align-middle me-2"></i>Sent
                    Mail</a>
                <a href="javascript:void(0);"><i class="bx bx-trash font-size-16 align-middle me-2"></i>Trash</a>
            </div>

            <h6 class="mt-4">Labels</h6>

            <div class="mail-list mt-1">
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-info me-2"></span>Theme
                    Support</a>
                <a href="javascript:void(0);"><span
                        class="mdi mdi-circle-outline text-warning me-2"></span>Freelance</a>
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-primary me-2"></span>Social</a>
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-danger me-2"></span>Friends</a>
                <a href="javascript:void(0);"><span class="mdi mdi-circle-outline text-success me-2"></span>Family</a>
            </div>

            <ng-template #content let-modal>
                <div class="modal-header">
                    <h5 class="modal-title" id="composemodalTitle">New Message</h5>
                    <button type="button" class="btn-close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <form>
                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="To">
                        </div>
                        <div class="mb-3">
                            <input type="text" class="form-control" placeholder="Subject">
                        </div>
                        <div class="mb-3">
                            <ckeditor [editor]="Editor" data="<p>Content of the editor.</p>"></ckeditor>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="modal.close('Close click')">Close</button>
                    <button type="button" class="btn btn-primary">Send <i
                            class="fab fa-telegram-plane ms-1"></i></button>
                </div>
            </ng-template>

        </div>
        <!-- End Left sidebar -->

        <!-- Right Sidebar -->
        <div class="email-rightbar mb-3">
            <div class="card">
                <div class="btn-toolbar p-3" role="toolbar">
                    <div class="btn-group me-2 mb-2 mb-sm-0">
                        <button type="button" class="btn btn-primary waves-light waves-effect"><i
                                class="fa fa-inbox"></i></button>
                        <button type="button" class="btn btn-primary waves-light waves-effect"><i
                                class="fa fa-exclamation-circle"></i></button>
                        <button type="button" class="btn btn-primary waves-light waves-effect"><i
                                class="far fa-trash-alt"></i></button>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0" ngbDropdown> 
                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle"
                            ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-folder"></i> <i class="mdi mdi-chevron-down ms-1"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Updates</a>
                            <a class="dropdown-item" href="javascript:void(0);">Social</a>
                            <a class="dropdown-item" href="javascript:void(0);">Team Manage</a>
                        </div>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0" ngbDropdown>
                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle"
                            ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-tag"></i> <i class="mdi mdi-chevron-down ms-1"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Updates</a>
                            <a class="dropdown-item" href="javascript:void(0);">Social</a>
                            <a class="dropdown-item" href="javascript:void(0);">Team Manage</a>
                        </div>
                    </div>
                    <div class="btn-group me-2 mb-2 mb-sm-0" ngbDropdown>
                        <button type="button" class="btn btn-primary waves-light waves-effect dropdown-toggle"
                            ngbDropdownToggle data-bs-toggle="dropdown" aria-expanded="false">
                            More <i class="mdi mdi-dots-vertical ms-2"></i>
                        </button>
                        <div class="dropdown-menu" ngbDropdownMenu>
                            <a class="dropdown-item" href="javascript:void(0);">Mark as Unread</a>
                            <a class="dropdown-item" href="javascript:void(0);">Mark as Important</a>
                            <a class="dropdown-item" href="javascript:void(0);">Add to Tasks</a>
                            <a class="dropdown-item" href="javascript:void(0);">Add Star</a>
                            <a class="dropdown-item" href="javascript:void(0);">Mute</a>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <div class="d-flex align-items-start mb-4">
                        <img class="d-flex me-3 rounded-circle avatar-sm" src="assets/images/users/avatar-2.jpg"
                            alt="Generic placeholder image">
                        <div class="flex-1">
                            <h5 class="font-size-14 my-1">Humberto D. Champion</h5>
                            <small class="text-muted">support@domain.com</small>
                        </div>
                    </div>
                    <h4 class="mt-0 mb-4 font-size-16">Your Storage is lonely. Add some files!</h4>
                    <p class="text-muted">Dear User,</p>
                    <p class="text-muted">Once your files are in Storage, they’ll be waiting for you anywhere you
                        install the app—like your computer, phone, or tablet. Your files will also be securely backed up
                        and easy to share, no matter what type of files they are.</p>
                    <p class="text-muted">An unrecognized device or browser recently signed into your Storage account.
                        Help us keep your account secure by letting us know if this was you:</p>
                    <p class="text-muted mb-0">Sincerly,</p>
                    <p class="text-muted">Pichforest</p>
                    <hr />
                    <div class="row">
                        <div class="col-xl-2 col-6">
                            <div class="card border shadow-none">
                                <img class="card-img-top img-fluid" src="assets/images/small/img-3.jpg"
                                    alt="Card image cap">
                                <div class="py-2 text-center">
                                    <a href="javascript: void(0);" class="fw-semibold font-size-13 text-reset">Download
                                        <i class="bx bxs-download align-middle"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-2 col-6">
                            <div class="card border shadow-none">
                                <img class="card-img-top img-fluid" src="assets/images/small/img-4.jpg"
                                    alt="Card image cap">
                                <div class="py-2 text-center">
                                    <a href="javascript: void(0);" class="fw-semibold font-size-13 text-reset">Download
                                        <i class="bx bxs-download align-middle"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <a href="" class="btn btn-light waves-effect mt-4"><i class="mdi mdi-reply"></i> Reply</a>
                </div>
            </div>
        </div>
        <!-- card -->
    </div>
    <!-- end Col-9 -->
</div>